import React from "react"
import Layout from '../components/layout'
import Slider from '../components/Slider'
import FeaturedVehicles from '../components/featured/FeaturedVehicles'
import VideoParagraph from '../components/video/VideoParagraphComponent'
import InstagramFeed from '../components/feed/InstagramFeed'
import AddtlButtons from '../components/additionalButtons/AddtlButtonsComponent'
import HTML from "../components/HTML"
import Directions from "../components/directions/Directions"
import OurSpecials from '../components/ourspecials/OurSpecials'
import AlphaSlider from '../components/alphaSlider/AlphaSlider'

function HomePage(props) {
    const { pageContext } = props
    const { pageContent, theme, dealerInfo, inventorymanagement,site_id, lead_url } = pageContext
    
    //This function should match the function in AlphaSlider Module by the same name. If you update this function also update that one.
    const getFirstEvergreen = (alphaslider) =>{
        const today = new Date(Date.now()).getTime()
        
        for (var i = 0; i < alphaslider.length; i++){
            let slide = alphaslider[i]
            const expiration = new Date(slide.expiration).getTime()
            const startDate = new Date(slide.startDate).getTime()
            if (startDate <= today && expiration - today > 157784630000) return slide //If the expiration is greater than 5 years out then we can assume it's an evergreen. This needs to be a setting later.
        }
        //If all the expiration are less than 5 years out we can assume we should just grab the first one. DRD 5/20/2024
        if(alphaslider.length > 0){
            return alphaslider[0]
        }
    }


    let components = [
        <Slider key={"Slider"} data={pageContent.HeroSlider} theme={theme} />,
        <OurSpecials data={pageContent.OurSpecials} theme={theme} />,
        <FeaturedVehicles key={"Featured"} data={pageContent.InventorySlider}
            theme={theme} featured={pageContent.InventorySlider.vins}
                          mainTitle={pageContent.InventorySlider.mainTitle}
            subTitle={pageContent.InventorySlider.subTitle}
            theSlug={inventorymanagement.vdpslug} 
            unavailableImg={inventorymanagement.unavailableImg}/>,
        <VideoParagraph key={"VideoParagraphComponent"} data={pageContent.About}
            theme={theme} />,
        <Directions key={"DirectionsHours"} data={{...pageContent.DirectionsHours, ...dealerInfo }}/>,
        <InstagramFeed data={pageContent.InstagramSection} />,
        <AddtlButtons disclaimerContact={inventorymanagement.disclaimerContact} data={pageContent.AddtlButtonsComponent} leadURL={lead_url} site_id={site_id} />,
        <AlphaSlider key={"Slider"} data={pageContent.AlphaSlider} theme={theme} />
    ]

    if(pageContent.hasOwnProperty("ArrayRawHTML")) {
        const { arrayRawHTML } = pageContent.ArrayRawHTML
        const rawComponents = arrayRawHTML.map(element => {  
            return <HTML data={{ 
                order: Number(element.order),
                visible: element.visible,
                rawHTML: element.rawHtml
            }} key={"HTML"} />
        })
        components.push(...rawComponents)
    }

    let sortedComponents = components.sort((a, b) =>
        parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
    ) // sorts the components based on json data
    // filters out the components that are not visible
    let visibleComponents = sortedComponents.filter(component => {
        return component.props.data.visible === true
    })
    return (
        <Layout seo={pageContent.SEO} pageImage={getFirstEvergreen(pageContent.AlphaSlider.alphaslider).imageSmall}>
            { visibleComponents}
        </Layout>
    )
}

export default HomePage

        // <BrandsComponent key={"BrandsCompontnt"} data={pageContent.BrandsComponent}
        //     theme={theme} brands={pageContent.BrandsComponent.brands} />,
        // <OurSpecials data={pageContent.OurSpecials} theme={theme} />,
        // <FeaturedComponent key={"Featured"} data={pageContent.FeaturedComponent}
        //     theme={theme} featured={pageContent.FeaturedComponent.featured} />,
        // <AboutComponent key={"AboutComponent"} data={pageContent.AboutComponent}
        //     about={pageContent.AboutComponent.about[0]} theme={theme} />,
