import React from 'react'
import Slider from "react-slick"
import PropTypes from 'prop-types'
import {Link} from "gatsby"
import "../scss/Slider.scss"
import "slick-carousel/slick/slick.css"
import ModalParagraph from './modal/modalParagraph/ModalParagraph.js'

class SliderComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentSlide: 0,
      totalSlides: 0,
      slides: this.props.data.Slider,
      first: true
    }

    this.slider = React.createRef()
    this.dotsCont = React.createRef()
  }


  slickNext() {
    this.slider.slickNext()
  }

  slickPrev() {
    this.slider.slickPrev()
  }

  slickGoTo(e, index) {
    this.slider.slickGoTo(index)
  }

  updateSlideData(index) {
    this.setState({
      currentSlide: index,
      first: false
    })

    let dots = document.getElementsByName('dots')
    let mobileDots = document.getElementsByName('dotsMobile')

    for (let x = 0; x < dots.length; x++) {
      dots[x].classList = 'slider-dots'
      mobileDots[x].classList = 'bottom-dotLine'
    }

    dots[index].classList = 'slider-dotsSelected'
    mobileDots[index].classList = 'bottom-dotLineSelected'
  }

  buildStats() {
      if (this.state.slides[this.state.currentSlide].mph === '') return <div style={{ display: 'none' }}></div>

    return (
      <div className="bottom-statsCont">
        
        {this.state.slides[this.state.currentSlide].sliderInfo1 != '' ? 
          <div className="bottom-stats">
            <div className='bottom-number'>{this.state.slides[this.state.currentSlide].sliderInfo1}</div>
            <div className='bottom-measurement'>{this.state.slides[this.state.currentSlide].sliderLabel1}</div>
          </div>  : ''
        }
        {this.state.slides[this.state.currentSlide].sliderInfo2 != '' ? 
          <div className="bottom-stats">
            <div className='bottom-number'>{this.state.slides[this.state.currentSlide].sliderInfo2}</div>
            <div className='bottom-measurement'>{this.state.slides[this.state.currentSlide].sliderLabel2}</div>
          </div>  : ''
        }
        {this.state.slides[this.state.currentSlide].sliderInfo3 != '' ? 
          <div className="bottom-stats">
            <div className='bottom-number'>{this.state.slides[this.state.currentSlide].sliderInfo3}</div>
            <div className='bottom-measurement'>{this.state.slides[this.state.currentSlide].sliderLabel3}</div>
          </div>  : ''
        }
        {this.state.slides[this.state.currentSlide].sliderInfo4 != '' ? 
          <div className="bottom-stats">
            <div className='bottom-number'>{this.state.slides[this.state.currentSlide].sliderInfo4}</div>
            <div className='bottom-measurement'>{this.state.slides[this.state.currentSlide].sliderLabel4}</div>
          </div>  : ''
        }

      </div>
    )
  }

  render() {

    const mainSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 7000,
      variableHeight: true,
      afterChange: (index) => {
        this.updateSlideData(index)
      }
    }
    let filterDates = (slide) => {
      const today = new Date(Date.now()).getTime()
      const expiration = new Date(slide.expiration).getTime()
      const startDate = new Date(slide.startDate).getTime()

      return (startDate <= today && today < expiration)
    }

    let filteredSlides = this.state.slides.filter(filterDates)

    if(filteredSlides.length == 1)
      filteredSlides = filteredSlides.concat(filteredSlides)

    let slides = filteredSlides.map((slide, i) => {
      let smBlank = (slide.imageLinkSm == '')
      let mdBlank = (slide.imageLinkMd == '')

      return (
        <div aria-label={slide.label} key={i} className="wrap-fixed">
          <div className="slide-wrapper">
          <picture>
            {smBlank ? '' : <source srcset={slide.imageLinkSm} media="(max-width: 800px)" />}
            {mdBlank ? '' : <source srcset={slide.imageLinkMd} media="(max-width: 1350px)" />}
            <img src={slide.imageLink} />
          </picture>
          </div>
        </div >
      )
    })

    let dots = filteredSlides.map((slide, i) => {
      if (this.state.first && i === 0) return <div className="dotContainer" onClick={(e) => this.slickGoTo(e, i)}><div name="dots" className="slider-dotsSelected"></div></div>

      return <div className="dotContainer" onClick={(e) => this.slickGoTo(e, i)}><div name="dots" className="slider-dots"></div></div>
    })

    let mobileDots = filteredSlides.map((slide, i) => {
      if (this.state.first && i === 0) return <div name="dotsMobile" className="bottom-dotLineSelected" onClick={(e) => this.slickGoTo(e, i)}></div>

      return <div name="dotsMobile" className="bottom-dotLine" onClick={(e) => this.slickGoTo(e, i)}></div>
    })

    return (
      <div className='slider-flex hero' style={{ position: "relative" }}>

        <div className="slider-cont homepage">
          <Slider ref={slider => (this.slider = slider)} {...mainSettings}>
            {slides}
          </Slider>
        </div>

        <div className="slider-dotsCont slider-fixed" >
          <div className="slider-dotsTop">
            <div className="slider-dotsFlex" ref={this.dotsCont}>{dots}</div>
          </div>
          <div className="slider-arrows">
            <button onClick={() => this.slickPrev()} className="slider-button"><i className="las la-angle-left slider-arrowSize"></i></button>
            <button onClick={() => this.slickNext()} className="slider-button"><i className="las la-angle-right slider-arrowSize"></i></button>
          </div>
        </div>

        <div className="slider-barCont resp-dots">
          <div className='bottom-top'>
            <div className="bottom-dots">
              {mobileDots}
            </div>
            <div className="bottom-text">
              MEET THE <p className='bottom-yellow'>
                {this.state.slides[this.state.currentSlide].titleTwo}
              </p>
            </div>
            {this.buildStats()}	
            <div className={this.state.slides[this.state.currentSlide].mph === '' ? "bottom-buttonCont-margin" : "bottom-buttonCont"}>
              <Link className="bottom-cta" to={this.state.slides[this.state.currentSlide].buttonLink}>
                {this.state.slides[this.state.currentSlide].buttonText}
              </Link>
              {this.state.slides[this.state.currentSlide].modalParaButtonText != '' ?
                <ModalParagraph className="bottom-disclaimer" modalParaButtonText={this.state.slides[this.state.currentSlide].modalParaButtonText}
                                modalParaContent={this.state.slides[this.state.currentSlide].modalParaContent}></ModalParagraph>  : ''}

              {//<Link className="bottom-cta">{this.state.slides[this.state.currentSlide].modalParaButtonText}</Link> : ''}
              }
            </div>
          </div>
        </div>

        <div className="mobile-Cont">
          <div className="mobile-arrowCont">
            <button className="mobile-arrow" onClick={() => this.slickPrev()}><i className="las la-angle-left"></i></button>
            <button className="mobile-arrow" onClick={() => this.slickNext()}><i className="las la-angle-right"></i></button>
          </div>
        </div>
      </div>
    )
  }
}

SliderComponent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.object
}

export default SliderComponent
